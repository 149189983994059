import React from 'react';
import QuizHistoryTable from '../components/ui/QuizHistoryTable';

const QuizAttemptHistoryPage = () => {
    return (
        <div>
            <QuizHistoryTable />
            {/*<QuizDataHistory/>*/}
        </div>
    );
};

export default QuizAttemptHistoryPage;
